@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import "rsuite/dist/rsuite.css";
html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7f9d;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #21295c;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #21295c;
}

.gamed {
  display: flex;
  flex-grow: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}


.loading-animation {
  animation-name: myAnimation;
  animation-duration: 2s;
  animation: myAnimation 2s ease-out forwards;
  animation-fill-mode: forwards;
}

@keyframes myAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}



.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  height: 400px;
  border: 5px dashed rgb(117, 112, 112);
  padding: 2rem;
}

.active {
  background-color: #5f5959;
}

button {
  padding: 1rem;
  margin: 1rem;
  font-size: medium;
}


.list-item {
  display: flex;
  padding: 0;
  margin: 8px 0;
  min-width: 300px;
  font-size: 22px;
  background-color: #d4d2d2;
  cursor: grab;
  border-radius: 10px;
}

.list-item > h3 {
  padding: 10px;
  margin: 0;
}

.list-item > span {
  margin-right: 10px;
  font-size: 24px;
  font-weight: bold;
  border-right: 2px solid white;
  padding: 10px;
  padding-right: 16px;
}

.next-position {
  border: 2px solid #34d44f;
  opacity: 0.2;
}